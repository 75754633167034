/* Override default color for bootstrap and other components that need color coding */
.btn-outline-success {
  --bs-btn-color: var(--bs-success);
  --bs-btn-border-color: var(--bs-success);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-success);
  --bs-btn-hover-border-color: var(--bs-success);
  --bs-btn-focus-shadow-rgb: 125, 167, 51;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: var(--bs-success);
  --bs-btn-active-border-color: var(--bs-success);
  --bs-btn-active-shadow: inset 0 3px 5px rgb(0 0 0 / 12.5%);
  --bs-btn-disabled-color: var(--bs-success);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-success);
  --bs-gradient: none;
}

.btn-success {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: var(--bs-success);
  --bs-btn-border-color: var(--bs-success);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-success);
  --bs-btn-hover-border-color: var(--bs-success);
  --bs-btn-focus-shadow-rgb: 106, 142, 43;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-success);
  --bs-btn-active-border-color: var(--bs-success);
  --bs-btn-active-shadow: inset 0 3px 5px rgb(0 0 0 / 12.5%);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-success);
  --bs-btn-disabled-border-color: var(--bs-success);
}

.btn-success:hover {
  opacity: 0.7;
}

.btn-outline-success:hover {
  opacity: 0.85;
}

.applyBtn {
  background-color: var(--bs-success);
  border-color: var(--bs-success);
}

.applyBtn:hover {
  opacity: 0.7;
  background-color: var(--bs-success);
  border-color: var(--bs-success);
}

.tabulator .tabulator-footer .tabulator-page.active {
  border-color: var(--bs-success);
  background-color: var(--bs-success);
  color: #fff;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--bs-success);
  border-color: transparent;
  color: #fff;
}

.daterangepicker .ranges li.active {
  background-color: var(--bs-success);
  color: #fff;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  display: inline-block;
  border-top: 6px solid #fff;
  border-right: 6px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  border-bottom: 6px solid var(--bs-success);
  border-left: 6px solid transparent;
}

.top-bar-divider {
  border-left: 2px solid var(--bs-success);
  height: 40px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  z-index: 9998;
}

.pac-container {
  z-index: 1051 !important;
}

form.sale-orderline-form {
  position: relative;
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 80%);

  /* Semi-transparent background */
  z-index: 2;

  /* Place the spinner on top */
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-error-message {
  color: red !important;
}

#deliveryModalAlertContainer {
  transition: all 0.7s;
  transition-timing-function: ease-in;
}

@media only screen and (width >= 768px) {
  .w-md-50 {
    width: 50% !important;
  }
}

@media only screen and (width <= 768px) {
  .error-page-text {
    font-size: 1.6rem !important;
  }
}
