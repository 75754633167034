/* styles.css */

.body-layout {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
}

.topnav-layout {
  grid-column: 1 / span 2;
  grid-row: 1 / 2;
}

.sidenav-layout {
  width: 16rem;
}

.content-layout {
  grid-column: 1 / span 2;
  grid-row: 2 / 2;
  background-color: #fafafa;
}

@media (min-width: 768px) {
  .content-layout {
    grid-column: 2 / 2;
    grid-row: 2 / 2;
  }

  .footer {
    grid-column: 2 / span 2;
    grid-row: 3 / 3;
  }
}

.nav-width {
  width: 16rem !important;
}

.side-nav-div:hover {
  background-color: #e1e1e1;
}

.navbar-brand {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-right: 0 !important;
}

.app-switch-icon {
  width: 25px;
  height: 25px;
}

.nav-icon {
  width: 16px;
  height: 16px;
}

.menu-icon {
  width: 25px;
  height: 25px;
}

.cds-nav-link:hover {
  background-color: #e6e6e6;
}

.switcher-card:hover {
  background-color: #e6e6e6;
}

/* Footer */
.footer {
  width: 100%;
  grid-column: 1 / span 2;
  grid-row: 3 / 3;
}

/* select2 */
.select2-hidden-accessible {
  position: relative !important;
}

/* tree */
.tree-list {
  width: 100%;
  overflow: auto;
  max-height: 200px;
}

/* google maps preview container */
.g-maps {
  height: 500px;
  width: 100%;
}

/* list group item */
.list-group-item {
  background-color: #fafafa !important;
  border: 0 !important;
}

@media (min-width: 992px) {
  .tree-list {
    width: 16rem !important;
    max-height: 100% !important;
  }
}

.iti {
  display: block !important;
}

/* Works on Firefox */
* {
  scrollbar-color: var(--bs-success) white;
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--bs-success);
  border-radius: 20px;
  border: 3px solid white;
}

.pointer-events-none {
  pointer-events: none;
}

.text-xs {
  font-size: x-small;
}

.font-extra-bold {
  font-weight: bolder;
}
